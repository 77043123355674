<template>
  <div v-if="scrollCtrl" class="s-m__wrapper">
    <div
      class="s-m__overlay"
      :class="{ 's-m__overlay--show': showBlocking }"
    >
      <span>{{ gestureLabel }}</span>
    </div>

    <div :id="`s-m__${name}`" class="s-m" ref="map"/>
  </div>
  <div v-else :id="`s-m__${name}`" class="s-m" ref="map"/>
</template>

<script>
import {
  ref,
  defineComponent,
  onMounted,
  onUnmounted,
  computed,
  getCurrentInstance
} from 'vue';

import MaplibreGL from 'maplibre-gl/dist/maplibre-gl'
import 'maplibre-gl/dist/maplibre-gl.css'
import debounce from 'lodash/debounce';
import { isMac } from '@vue-storefront/core/helpers';

export default defineComponent({
  name: 'ShippingMap',
  props: {
    name: {
      type: String,
      required: true
    },
    scrollCtrl: {
      type: Boolean,
      default: () => false
    },
    config: {
      type: Object,
      required: true
    },
    mapZoom: {
      type: Number,
      default: 5
    },
    mapCenter: {
      type: Object,
      default: () => null
    }
  },
  setup (props, { emit }) {
    const map = ref(null)
    const showBlocking = ref(false)
    const showBlockingTouch = ref(false)
    const showBlockingCanceled = ref(false)
    const wheelBlocking = ref(false)

    const isTouchDevice = computed(() => (
      (('ontouchstart' in window) ||
        (navigator.maxTouchPoints > 0) ||
        (navigator.msMaxTouchPoints > 0))
    ))
    const { proxy: vm } = getCurrentInstance()

    const gestureLabel = computed(() => {
      if (!props.scrollCtrl) return ''

      if (isTouchDevice.value) {
        return vm.$t('Two finger move map')
      }

      if (isMac) {
        return vm.$t('Mac scroll map')
      }

      return vm.$t('Text scroll map')
    })

    const hideBlockingHandler = debounce(() => {
      showBlocking.value = false
    }, 1500)

    const showBlockingHandler = debounce(() => {
      if (showBlockingCanceled.value) {
        showBlockingCanceled.value = false
        return
      }

      showBlocking.value = true
    }, 50)

    const touchHandler = (e) => {
      if (e.type !== 'touchmove' && e.type !== 'touchstart') {
        hideBlockingHandler()
        return;
      }

      if (e.type === 'touchcancel' || e.type === 'touchend') {
        showBlockingCanceled.value = true
        map.value.dragPan.disable();
        return;
      }

      if (e.touches.length === 1) {
        showBlockingTouch.value = true
        showBlockingHandler()

        map.value.dragPan.disable();
        hideBlockingHandler()
      } else {
        showBlocking.value = false
        map.value.dragPan.enable();
      }
    }

    onMounted(() => {
      map.value = new MaplibreGL.Map({
        container: `s-m__${props.name}`,
        style: '/assets/map/style.json',
        doubleClickZoom: false,
        scrollZoom: false,
        touchZoomRotate: !props.scrollCtrl,
        touchPitch: false,
        dragPan: props.scrollCtrl ? !isTouchDevice.value : true,
        dragRotate: false,
        center: props.mapCenter,
        zoom: props.mapZoom
      });

      map.value.scrollZoom.disable();

      const zoom = debounce((event) => {
        if (wheelBlocking.value) return

        wheelBlocking.value = true

        const center = map.value.getCenter();
        const zoom = map.value.getZoom();
        const delta = event.deltaY > 0 ? -0.5 : 0.5;

        map.value.easeTo({
          center: center,
          zoom: zoom + delta,
          duration: 50
        })

        wheelBlocking.value = false
      }, 10)

      vm.$refs.map.addEventListener('wheel', (event) => {
        if (props.scrollCtrl && !(event.ctrlKey || event.metaKey)) {
          showBlockingTouch.value = false
          showBlockingHandler()
          hideBlockingHandler()
          return
        }

        event.preventDefault();

        return zoom(event)
      })

      if (props.scrollCtrl) {
        const canvas = map.value.getCanvas()

        canvas.addEventListener('touchstart', touchHandler, { passive: false });
        canvas.addEventListener('touchmove', touchHandler, { passive: false });
        canvas.addEventListener('touchend', touchHandler, { passive: false });
        canvas.addEventListener('touchcancel', touchHandler, { passive: false });
        canvas.addEventListener('click', touchHandler, { passive: false });
      }

      map.value.dragRotate.disable();
      map.value.touchZoomRotate.disableRotation();
      map.value.setPitch(0);
      map.value.setMaxPitch(0);

      map.value.on('moveend', () => {
        emit('update:center', map.value.getCenter());
      })

      map.value.on('zoomend', (e) => {
        emit('update:zoom', e.target._zoom);
      })

      map.value.on('load', () => {
        emit('ready');
      });
    })

    onUnmounted(() => {
      if (props.scrollCtrl) {
        const canvas = map.value.getCanvas()

        canvas.removeEventListener('touchstart', touchHandler);
        canvas.removeEventListener('touchmove', touchHandler);
        canvas.removeEventListener('touchend', touchHandler);
        canvas.removeEventListener('touchcancel', touchHandler);
        canvas.removeEventListener('click', touchHandler);
      }

      if (map.value && map.value.remove) {
        map.value.off();
        map.value.remove();
      }
    })

    return {
      mapObject: map,
      gestureLabel,
      showBlocking
    }
  }
});
</script>

<style lang="scss" scoped>
.s-m {
  width: 100%;
  height: 100%;

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    position: relative;

    .s-m__overlay {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;

      background: rgba(0, 0, 0, 0.7);
      color: var(--white);
      font-size: var(--font-size-20);
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      transition: opacity 500ms;
      opacity: 0;
      padding: var(--spacer-20);
      text-align: center;

      &--show {
        opacity: 1;
      }
    }
  }

  ::v-deep {
    .maplibregl-popup-tip, .maplibregl-popup-close-button {
      display: none!important;
    }

    .shp-area-map__popup-title {
      color: var(--dark-gray);
    }

    .maplibregl-popup-content {
      font: var(--font-family-primary);
      line-height: 1.3;
      font-size: 13px;
      font-size: 1.08333em;
      padding: 6px 8px;
      border-radius: 6px;
    }
  }
}
</style>
