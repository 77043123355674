export const shippingMapClusterNewPost = (pointsData) => ({
  type: 'FeatureCollection',
  features: pointsData.map(point => ({
    type: 'Feature',
    properties: {
      id: point.ref
    },
    geometry: {
      type: 'Point',
      coordinates: [+point.long, +point.lat]
    }
  }))
})

export const shippingMapClusterPickup = (pointsData, type) => ({
  type: 'FeatureCollection',
  features: pointsData.map(point => ({
    type: 'Feature',
    properties: {
      id: point.id,
      type
    },
    geometry: {
      type: 'Point',
      coordinates: [+point.long, +point.lat]
    }
  }))
})

export const shippingMapClusterShops = (pointsData) => ({
  type: 'FeatureCollection',
  features: pointsData.map(point => ({
    type: 'Feature',
    properties: {
      id: point.id
    },
    geometry: {
      type: 'Point',
      coordinates: [+point.long, +point.lat]
    }
  }))
})
